export const METAMASK_WALLET = {
  id: "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
  name: "MetaMask",
  homepage: "https://metamask.io/",
  image_id: "5195e9db-94d8-4579-6f11-ef553be95100",
  order: 10,
  mobile_link: "metamask://",
  desktop_link: null,
  webapp_link: null,
  app_store: "https://apps.apple.com/us/app/metamask/id1438144202",
  play_store: "https://play.google.com/store/apps/details?id=io.metamask",
  rdns: "io.metamask",
  chrome_store:
    "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
  injected: [
    {
      namespace: "eip155",
      injected_id: "isMetaMask",
    },
  ],
};

export const OKX_WALLET = {
  id: "971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709",
  name: "OKX Wallet",
  homepage: "https://www.okx.com/web3",
  image_id: "45f2f08e-fc0c-4d62-3e63-404e72170500",
  order: 140,
  mobile_link: "okex://main",
  desktop_link: null,
  webapp_link: "https://www.okx.com/download",
  app_store:
    "https://apps.apple.com/us/app/okx-buy-bitcoin-eth-crypto/id1327268470",
  play_store: "https://play.google.com/store/apps/details?id=com.okinc.okex.gp",
  rdns: "com.okex.wallet",
  chrome_store:
    "https://chrome.google.com/webstore/detail/okx-wallet/mcohilncbfahbmgdjkbpemcciiolgcge",
  injected: [
    {
      namespace: "eip155",
      injected_id: "isPLC",
    },
    {
      namespace: "solana",
      injected_id: "isPLC",
    },
  ],
};

"use client";
import Button from "@marginly/ui/components/button";
import Thumbnail from "@marginly/ui/components/thumbnail";
import T from "@marginly/ui/components/typography";
import { RouterController } from "@web3modal/core";
import React, { MouseEvent, useContext, useMemo } from "react";
import { useConnect } from "wagmi";
import { metaMask } from "wagmi/connectors";

import Space from "@/components/common/space";
import { METAMASK_WALLET, OKX_WALLET } from "@/constants/wallets";
import { Web3Context } from "@/contexts/web3/context";
import {
  ButtonsRow,
  Modal,
  ModalBody,
  ModalLayout,
} from "@/external/components/modal.styled";

import Portal from "../common/portal";
// import { useWallets } from "@/hooks/wallet-connect-custom";

type WalletName = "metamask" | "okx";

const walletByWalletName: Record<
  WalletName,
  typeof METAMASK_WALLET | typeof OKX_WALLET
> = {
  metamask: METAMASK_WALLET,
  okx: OKX_WALLET,
};

const idByWalletName: Record<WalletName, string> = {
  metamask: "io.metamask",
  okx: "com.okex.wallet",
};

export default function ConnectWalletModal({ onHide }: { onHide: () => void }) {
  const { open } = useContext(Web3Context);
  const { connectors, connect: wagmiConnect } = useConnect();

  const connect = useMemo(
    () => (walletName: WalletName) => (e: MouseEvent) => {
      e.preventDefault();
      const wallet = walletByWalletName[walletName];
      const connectorId = idByWalletName[walletName];
      const connector = connectors.find(
        (connector) => connector.id === connectorId,
      );

      if (connector) {
        wagmiConnect({ connector });
        onHide();
        return;
      }

      open({ view: "Connect" }).then(() => {
        RouterController.push("ConnectingWalletConnect", { wallet });
        onHide();
      });
    },
    [connectors, onHide, open, wagmiConnect],
  );

  // const getWallet = useMemo(() => {
  //   let metamask = true;
  //   let okx = true;

  //   for (const connector of connectors) {
  //     if (connector.name === METAMASK_WALLET.name) {
  //       metamask = false;
  //     } else if (connector.name === OKX_WALLET.name) {
  //       okx = false;
  //     }
  //   }

  //   for (const recent of recents) {
  //     if (recent.name === METAMASK_WALLET.name) {
  //       metamask = false;
  //     } else if (recent.name === OKX_WALLET.name) {
  //       okx = false;
  //     }
  //   }

  //   return {
  //     metamask,
  //     okx,
  //   };
  // }, [connectors, recents]);

  return (
    <Portal>
      <Modal className="connect">
        <ModalLayout onClick={onHide} />
        <Button onClick={onHide} className="close">
          <img src="/images/close.svg" alt="" />
        </Button>
        <ModalBody className="connect-body">
          <Thumbnail xl className="thumbnail">
            <img src="/images/wallet-connect.svg" alt="" />
          </Thumbnail>
          <Space height={40} heightMobile={40} />
          <T headerM>Connect your wallet</T>
          <Space height={48} heightMobile={48} />
          <ButtonsRow>
            <Button className="btn btn-metamask" onClick={connect("metamask")}>
              <img src="/images/metamask.svg" alt="" />
              <T action className="primary-p">
                {/* {getWallet.metamask ? `Get ` : ""} */}
                MetaMask
              </T>
            </Button>

            <Button secondary className="btn btn-okx" onClick={connect("okx")}>
              <img src="/images/okx.svg" alt="" />
              <T action>
                {/* {getWallet.okx ? `Get ` : ""}  */}
                OKX Wallet
              </T>
            </Button>

            <Button
              secondary
              className="btn btn-wc"
              onClick={() => open({ view: "Connect" }).then(() => onHide())}
            >
              <img src="/images/walletconnect.svg" alt="" />
              <T action>Use WalletConnect</T>
            </Button>
          </ButtonsRow>
        </ModalBody>
      </Modal>
    </Portal>
  );
}

import styled from "styled-components";

export const Modal = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  z-index: 9999;

  .close {
    position: absolute;
    top: 32px;
    right: 32px;
    background: transparent;
    img {
      filter: invert(var(--svg-invert));
    }
    @media (max-width: 1023px) {
      display: none;
    }
  }
`;

export const ModalLayout = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--background-overflay);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  max-width: 100%;
  height: calc(100% - 128px);
  max-height: calc(100% - 128px);
  box-shadow: 0px 8px 32px rgba(51, 20, 0, 0.16);
  overflow: hidden;
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  max-height: calc(100vh - 64px);
  max-height: calc(100dvh - 64px);
  border-radius: 32px 32px 0 0;
  color: var(--text-primary);
  background: var(--background-elevated);

  .action,
  .headerL,
  .headerM,
  .headerS {
    font-weight: 400;
    font-variation-settings: "wght" 700;
  }

  &.short {
    height: fit-content;
  }

  &.padded,
  .padded {
    padding: 20px 24px 12px;
  }

  &.share {
    height: fit-content;
    max-width: 420px;
    width: 100%;
    box-sizing: border-box;
    padding: var(--spacing-space-34, 34px) var(--spacing-space-24, 24px)
      var(--spacing-space-24, 24px) var(--spacing-space-24, 24px);
    .headerM {
      font-size: 24px;
      font-style: normal;
      line-height: 32px;
      letter-spacing: 0.24px;
      text-align: center;
      font-variation-settings: "wght" 700;
    }
  }

  &.sm {
    width: 520px;
  }
  &.high {
    height: calc(100% - 96px);
    max-height: calc(100% - 96px);
  }
  &.middle-high {
    height: calc(100% - 74px);
    max-height: calc(100% - 74px);
  }
  &.rubber-height {
    height: auto;
    max-height: initial;
  }
  &.notification {
    height: auto;
    width: 520px;
    min-height: 278px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 48px 24px;
    box-sizing: border-box;
    max-width: calc(100% - 18px);
    bottom: 9px;
    border-radius: 32px;

    @media (min-width: 1024px) {
      top: 50%;
      bottom: auto;
    }
  }

  &.empty-position {
    height: 278px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: var(--text-primary);
    font-family: "PlusJakartaSans", "Medium", sans-serif;
  }

  &.connect-body {
    width: 520px;
    padding: 56px 48px 48px;
    text-align: center;
    height: fit-content;
  }
  .primary-p {
    color: var(--text-invert-primary, #fff);
  }

  @media (max-width: 1023px) {
    &.connect-body {
      padding: 48px 24px 24px;
      width: calc(100% - 16px);
      bottom: 8px;
      border-radius: var(--rounding-radius-xxl, 32px);
    }
  }

  @media (min-width: 1023px) {
    max-height: calc(100vh - 64px);
    border-radius: 32px 32px 0 0;
    left: 50%;
    top: 50%;
    bottom: auto;
    transform: translate(-50%, -50%);
    height: fit-content;
    border-radius: 48px;

    &.padded {
      padding: 24px;
      overflow: auto;
    }

    &.empty-position {
      height: fit-content;
    }
  }

  scroll-behavior: smooth;
  scrollbar-width: none;
  scrollbar-color: rgba(255, 255, 255, 0);
  -ms-overflow-style: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar,
  &::-moz-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0 !important;
    height: 0 !important;
    display: none !important;
    opacity: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar:horizontal {
    width: 0 !important;
    height: 0 !important;
    display: none !important;
    opacity: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track-piece,
  &::-webkit-resizer {
    display: none;
    width: 0 !important;
    height: 0 !important;
    opacity: 0;
    background: transparent;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
    opacity: 0;
  }
  &::-webkit-scrollbar-track {
    opacity: 0;
    background-color: transparent;
    width: 0 !important;
    height: 0 !important;
  }

  .thumbnail {
    margin: 0 auto;
    img {
      filter: invert(var(--svg-invert));
    }
    @media (max-width: 1023px) {
      width: 64px;
      height: 64px;
      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  &.blast {
    padding: var(--spacing-space-56, 56px) var(--spacing-space-48, 48px)
      var(--spacing-space-48, 48px) var(--spacing-space-48, 48px);
    border-radius: var(--rounding-radius-xxxl, 48px);
    background: #c8ff26;
    box-shadow: 0px 8px 32px 0px rgba(51, 20, 0, 0.16);
    height: fit-content;
    text-align: center;
    overflow: hidden;
    p {
      color: #000;
    }
    button.text {
      width: 100%;
      .action {
        color: var(--text-primary-on-light, #000);
      }
    }
    button.elevated {
      background: #fff;
    }
    button {
      width: 100%;
    }
    .guy {
      width: 64px;
      height: 64px;
      display: block;
      margin: 0 auto;
      @media (min-width: 1024px) {
        width: 96px;
        height: 96px;
      }
    }
    .guy-wrapper {
      position: relative;
    }
    .decor {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -2;
      object-fit: contain;
      top: 34px;
      width: 440px;
      @media (min-width: 1023px) {
        top: 50px;
        width: 520px;
      }
    }
    .headerM {
      font-size: 24px;
      @media (min-width: 1024px) {
        font-size: 32px;
      }
    }

    @media (max-width: 1023px) {
      left: 10px;
      right: 10px;
      bottom: 8px;
      transform: translate(0, 0);
      width: auto;
      padding: var(--spacing-space-48, 48px) var(--spacing-space-24, 24px)
        var(--spacing-space-24, 24px) var(--spacing-space-24, 24px);

      .close {
        display: none;
      }
    }
  }

  &.step {
    text-align: center;
    .primary .action {
      color: var(--text-invert-primary, #000);
    }
    @media (min-width: 1024px) {
      .headerM {
        font-size: 32px;
        letter-spacing: 0.32px;
        line-height: 40px;
      }
    }
  }
`;

export const ButtonsRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  .btn {
    border-radius: var(--rounding-radius-m, 16px);
    height: 64px;
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .btn-okx img {
    filter: invert(var(--svg-invert)) !important;
  }
  @media (max-width: 1023px) {
    gap: 8px;
    .btn-wc {
      background: var(--fill-primary, #000);
      order: 0;
      p {
        color: var(--text-invert-primary, #fff);
      }
    }
    .btn-okx {
      order: 2;
    }
    .btn-metamask {
      display: none;
    }
  }
`;

export const ModalHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 200ms ease-out;
  background: transparent;
  padding-bottom: 12px;

  &.filled {
    padding: 24px;
    background: #f7f3f2;
    background-blend-mode: multiply;
  }
  &.colored {
    background: #f7f3f2;
  }

  &.no-flex {
    display: block;
  }
  .subflex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  @media (min-width: 1024px) {
    padding-bottom: 24px;
  }
`;

export const ModalOverflow = styled.div`
  max-height: 90%;
  overflow: scroll;
  &.auto-height {
    height: auto;
  }
  &.full-height {
    height: 100%;
  }
  .epic-item {
    margin-left: 24px;
  }

  scroll-behavior: smooth;
  scrollbar-width: 0px;
  scrollbar-color: rgba(255, 255, 255, 0);
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  height: calc(100% - 104px);
  padding-bottom: 14px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0 !important;
    height: 0 !important;
    display: none !important;
    opacity: 0;
    background-color: transparent;
  }

  &::-moz-scrollbar {
    width: 0 !important;
    height: 0 !important;
    display: none !important;
    opacity: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar:horizontal {
    width: 0 !important;
    height: 0 !important;
    display: none !important;
    opacity: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 0 !important;
    height: 0 !important;
    opacity: 0;
    background: rgba(255, 255, 255, 0);
    background-color: transparent;
    display: none !important;
  }
  &::-webkit-scrollbar-track-piece {
    width: 0 !important;
    height: 0 !important;
    opacity: 0;
    background: rgba(255, 255, 255, 0);
    background-color: transparent;
    display: none !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
    opacity: 0;
  }

  &::-webkit-scrollbar-track {
    opacity: 0;
    background: transparent;
    background-color: transparent;
    width: 0 !important;
    height: 0 !important;
    display: none !important;
  }

  &.padded {
    padding-bottom: 14px;
  }
  @media (min-width: 1024px) {
    height: calc(100vh - 124px - 72px);
    padding-bottom: 24px;

    &.padded {
      padding-bottom: 44px;
    }
    &.auto-height {
      height: auto;
    }
    &.full-height {
      height: 100%;
    }
    &.short {
      max-height: calc(100vh - 124px - 72px);
      height: fit-content;
    }
  }
`;

export const ModalFarm = styled(Modal)`
  &.farm {
    ${ModalBody} {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: translate(0, 0);
      padding: 64px 24px 64px;
      height: 100%;
      max-height: 100%;
      border-radius: 0;
      overflow: auto;
      @media (min-width: 1024px) {
        max-width: 520px;
        width: 520px;
        left: 50%;
        top: 50%;
        right: auto;
        bottom: auto;
        transform: translate(-50%, -50%);
        border-radius: var(--rounding-radius-xxxl, 48px);
        background: var(--background-elevated, #fff);
        box-shadow: 0px 8px 32px 0px rgba(51, 20, 0, 0.16);
        height: fit-content;
        padding: 32px;
        max-height: calc(100% - 128px);
      }

      &.step {
        padding: var(--spacing-space-56, 56px) var(--spacing-space-48, 48px)
          var(--spacing-space-48, 48px) var(--spacing-space-48, 48px);

        @media (max-width: 1023px) {
          padding: var(--spacing-space-48, 48px) var(--spacing-space-24, 24px)
            var(--spacing-space-24, 24px) var(--spacing-space-24, 24px);
          left: 9px;
          bottom: 8px;
          right: 8px;
          top: auto;
          height: fit-content;
          border-radius: var(--rounding-radius-xxl, 32px);
        }
      }

      .close--mobile {
        display: block;
        top: 16px;
        left: 14px;
        right: auto;
        padding: 0;
        @media (min-width: 1024px) {
          display: none;
        }
      }
    }
    .btn--action {
      font-weight: 400;
      font-variation-settings: "wght" 700;
      width: 100%;
    }

    .input {
      max-width: 190px;
    }
  }
  .dropdown-button img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.24;
    .input__area {
      background: var(--fill-secondary, rgba(204, 187, 184, 0.16));
    }
  }
  .secondary .swap-icon {
    filter: invert(var(--svg-invert)) !important;
  }

  .thumbnail {
    background: var(--fill-accent-secondary);
    @media (max-width: 1024px) {
      padding: 16px;
      svg {
        width: 48px;
        height: 48px;
      }
    }
  }
`;

export const BonusesCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
